/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-assets', 'labelMore', 'Näita rohkem');
Labels.set('ui-assets', 'labelLess', 'Näita vähem');
Labels.set(
    'ui-assets',
    'labelToggleContent',
    'Hetkel on {visibleAssets} {allAssets}-st loendielemendist nähtav.'
);
/* eslint-enable max-len */
