/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set(
    'ui-tabs',
    'arrowKeys',
    'Nooleklahvidega saab vahekaartidel liikuda'
);
Labels.set('ui-tabs', 'selectTab', 'Vali vahekaart');

/* eslint-enable max-len */
