import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-comparison-cards', 'compareDetails', 'Võrdle detaile');
Labels.set('ui-comparison-cards', 'available', 'Olemas');
Labels.set('ui-comparison-cards', 'notAvailable', 'Pole olemas');
Labels.set('ui-comparison-cards', 'comparisonItem1', 'Võrdluses toode 1');
Labels.set('ui-comparison-cards', 'comparisonItem2', 'Võrdluses toode 2');
Labels.set(
    'ui-comparison-cards',
    'selectProductsCompare',
    'Vali tooted, mida võrrelda.'
);
