/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('validation', 'input', 'Palun sisesta korrektsed andmed.');
Labels.set('validation', 'date', 'Palun sisesta korrektsed andmed.');
Labels.set('validation', 'time', 'Palun sisesta aeg.');
Labels.set('validation', 'range', 'Viga sisestamisel.');
Labels.set('validation', 'month', 'Palun sisesta kuu.');
Labels.set(
    'validation',
    'datetime-local',
    'Palun sisesta kellaaeg ja kuupäev.'
);
Labels.set('validation', 'custom', 'Viga, palun kontrolli sisestatud andmeid.');
Labels.set('validation', 'pattern', 'Palun vali formaat.');
Labels.set(
    'validation',
    'rangeOverflow',
    'Väärtus peab olema sama või väiksem kui %s.'
);
Labels.set(
    'validation',
    'rangeUnderflow',
    'Väärtus peab olema sama või suurem kui %s.'
);
Labels.set(
    'validation',
    'step',
    'Samm peab olema %s, lähimad numbrid on %s ja %s.'
);
Labels.set('validation', 'long', 'Väärtus on liiga pikk, max %s sümbolit.');
Labels.set('validation', 'short', 'Väärtus on liiga lühike, min %s sümbolit.');
Labels.set('validation', 'type', 'Palun sisesta korrektsed andmed.');
Labels.set('validation', 'email', 'Palun sisesta e-posti aadress.');
Labels.set('validation', 'url', 'Palun sisesta URL.');
Labels.set('validation', 'value', 'Palun täida see väli.');

// User defined rule validations
Labels.set('validation', 'matches', 'Väljade andmed ei kattu.');
Labels.set('validation', 'checkboxes', 'Palun vali vähemalt %s valik(ut).');
Labels.set('validation', 'remote', 'Serveri viga.');
Labels.set(
    'validation',
    'maxfilesize',
    'Faili(-de) suurus on suurem kui %s Kb.'
);
Labels.set(
    'validation',
    'requiredMultiple',
    'Palun täitke vähemalt %s väli(-ja).'
);
/* eslint-enable max-len */
