import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-stepper', {
    month: {
        1: 'kuu',
        2: 'kuud',
    },
    year: {
        1: 'aasta',
        2: 'aastat',
    },
    increase: 'Suurenda',
    decrease: 'Vähenda',
    value: 'Väärtus',
});
