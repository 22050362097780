import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-slider', {
    month: {
        1: 'kuu',
        2: 'kuud',
    },
    year: {
        1: 'aasta',
        2: 'aastat',
    },
    value: 'Väärtus',
});
