import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-monthpicker', 'months', [
    'Jaanuar',
    'Veebruar',
    'Märts',
    'Aprill',
    'Mai',
    'Juuni',
    'Juuli',
    'August',
    'September',
    'Oktoober',
    'November',
    'Detsember',
]);
Labels.set('ui-monthpicker', 'year', 'Aasta');
Labels.set('ui-monthpicker', 'month', 'Kuu');
