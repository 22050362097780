/* eslint-disable max-len */

import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-feedback', 'MIN_RATE', 'Keeruline');
Labels.set('ui-feedback', 'MAX_RATE', 'Lihtne');
Labels.set('ui-feedback', 'POSITIVE', 'Jah');
Labels.set('ui-feedback', 'NEGATIVE', 'Ei');
Labels.set('ui-feedback', 'CONFIRM', 'Saada');
Labels.set(
    'ui-feedback',
    'TEXTAREA_LABEL',
    'Mida saame muuta paremaks või teisiti teha?'
);
Labels.set(
    'ui-feedback',
    'HINT_MESSAGE',
    'Tagasiside on anonüümne. Kasutame seda oma toodete ja teenuste arendamisel.'
);
Labels.set('ui-feedback', 'SUCCESS_MESSAGE', 'Täname Sind tagasiside eest!');
Labels.set('ui-feedback', 'ERROR_MESSAGE', 'Feedback sending failed');
