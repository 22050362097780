/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-textcounter', 'remaining', 'Kasutatavaid sümboleid:');
Labels.set(
    'ui-textcounter',
    'invalid',
    'Vabateksti väljale sisestatud tekst on liiga pikk (maks %d tähemärki).'
);
/* eslint-enable max-len */
