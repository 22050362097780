/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-calendar', 'weekDays', ['E', 'T', 'K', 'N', 'R', 'L', 'P']);
Labels.set('ui-calendar', 'weekDaysLong', [
    'Esmaspäev',
    'Teisipäev',
    'Kolmapäev',
    'Neljapäev',
    'Reede',
    'Laupäev',
    'Pühapäev',
]);
Labels.set('ui-calendar', 'prevMonth', 'Eelmine kuu');
Labels.set('ui-calendar', 'nextMonth', 'Järgmine kuu');
Labels.set(
    'ui-calendar',
    'arrowKeys',
    'Nooleklahvidega saab navigeerida kuupäevades'
);
/* eslint-enable max-len */
