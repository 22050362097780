/* eslint-disable max-len */
import { Labels } from '../../shared-components/global/labels.js';

Labels.set('ui-cookie-consent', 'title', 'Meie küpsised, Sinu valikud');
Labels.set(
    'ui-cookie-consent',
    'info',
    '{country} registreeritud Swedbanki Grupi {entitiesLink} kasutavad {cookiesLink} (või sarnaseid tehnoloogiaid), et pakkuda Sulle personaalsemat kogemust ja parandada veebilehe toimivust. Täpsema info saamiseks vaata meie {policyLink}.\n\nVajutades „Nõustun“, annad nõusoleku valikuliste küpsiste (analüütilised ja sihtturunduslikud) kasutamiseks. Vajutades „Keeldun“, ei nõustu Sa ühegagi neist küpsistest. Vajutades „Valin ise küpsised“, saad ise valida, milliste küpsistega soovid nõustuda. Võid oma küpsiste nõusoleku igal ajal tagasi võtta {usageLink}.'
);
Labels.set(
    'ui-cookie-consent',
    'disclaimer',
    'Mõned küpsised on selle veebilehe tööks hädavajalikud. Neid küpsiseid töödeldakse meie õigustatud huvi alusel, seega pole nende kohta vaja kasutaja nõusolekut. Sellel veebilehel kasutatakse ka kolmanda osapoole küpsiseid.'
);
Labels.set('ui-cookie-consent', 'accept', 'Nõustun');
Labels.set('ui-cookie-consent', 'decline', 'Keeldun');
Labels.set('ui-cookie-consent', 'saveChoice', 'Salvesta minu valik');
Labels.set('ui-cookie-consent', 'myCookies', 'Valin ise küpsised');
Labels.set(
    'ui-cookie-consent',
    'policyLinkLabel',
    'küpsiste kasutamise põhimõtteid'
);
Labels.set('ui-cookie-consent', {
    HELP_NECESSARY:
        'Võimaldavad juurdepääsu veebisaidi põhilistele osadele, mis on selle tööks hädavajalikud. Neid ei saa välja lülitada ja seetõttu kasutaja nõusolekut ei küsita.',
    HELP_ANALYTICAL:
        'Aitavad parandada veebisaidi jõudlust, võimaldades arvestada saidi külastusi ja liikluse allikaid. Kui Sa nendega ei nõustu, ei kajastu Sinu liikumine sellel veebisaidil meie statistikas.',
    HELP_TARGETING:
        'Aitavad pakkuda veebisaidil isikupärastatud teabe esitamist meie toodete ja teenuste kohta. Kui Sa nendega ei nõustu, ei näidata Sulle isikupärastatud sisu.',
});
Labels.set('ui-cookie-consent', {
    CATEGORY_NECESSARY: 'Vajalikud',
    CATEGORY_ANALYTICAL: 'Analüütilised',
    CATEGORY_TARGETING: 'Sihtturunduse',
});
Labels.set('ui-cookie-consent', 'EE', 'Eestis');
Labels.set('ui-cookie-consent', 'LV', 'Lätis');
Labels.set('ui-cookie-consent', 'LT', 'Leedus');
Labels.set('ui-cookie-consent', 'entities', 'üksused');
Labels.set('ui-cookie-consent', 'cookies', 'küpsiseid');
Labels.set('ui-cookie-consent', 'language', 'Keel');
Labels.set('ui-cookie-consent', 'usage', 'küpsiste lehel');
/* eslint-enable max-len */
